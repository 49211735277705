import React, { useState, useEffect } from "react";
import { collection, query, where, orderBy, onSnapshot } from "firebase/firestore";
import { db } from "./firebase";
import { ListGroup, Modal } from 'react-bootstrap';

function UserList({ agencyAccessCode }) {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);

  useEffect(() => {
    const q = query(
      collection(db, "data"),
      orderBy("createDateTime", "desc")
    );

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      setUsers(querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    });

    return () => unsubscribe();
  }, [agencyAccessCode]);

  const openPopup = (user) => {
    setSelectedUser(user);
  };

  const closePopup = () => {
    setSelectedUser(null);
  };

  return (
    <div>
      <ListGroup>
        {users.map((user) => (
          <ListGroup.Item key={user.id} className="d-flex justify-content-between align-items-center" onClick={() => openPopup(user)}>
            <div>
              {user.callType}
              <div>{user.location}</div>
            </div>
            <div>
            <div>{user.quadrant}</div>
            </div>
          </ListGroup.Item>
        ))}
      </ListGroup>

      <Modal show={selectedUser !== null} onHide={closePopup}>
        <Modal.Header closeButton>
          <Modal.Title>Narrative</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedUser?.narratives
            ?.sort((a, b) => b.enteredDateTime - a.enteredDateTime)
            ?.map((narrativeObj, index) => (
              <div key={index}>{narrativeObj.narrative}</div>
            ))}
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary" onClick={closePopup}>Close</button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default UserList;
