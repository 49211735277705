// src/App.js

import React, { useEffect, useState } from 'react';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import { auth, db } from './firebase';
import Login from './Login';
import UserList from './UserList';
import { Navbar, Container, Button, Alert } from 'react-bootstrap';
import NotAdmin from './NotAdmin';


function App() {
  const [user, setUser] = useState(null);
  const [agencyAccessCode, setAgencyAccessCode] = useState(null);
  const [adminAlert, setAdminAlert] = useState(false); // new state for admin alert

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        const docRef = doc(db, "webcadPNLOU", user.uid);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          setAgencyAccessCode(docSnap.data().agencyAccessCode);
          setUser(user);
          setAdminAlert(false); // clear admin alert on successful login
        } else {
          setAdminAlert(true); // show admin alert if user is not an admin
        }
      } else {
        setUser(null);
        setAgencyAccessCode(null);
      }
    });

    return unsubscribe;
  }, []);

  const logout = () => {
    signOut(auth);
  }

  return (
    <div className="d-flex flex-column vh-100">
      <Navbar bg="dark" variant="dark">
        <Container>
          <Navbar.Brand>NCI WebCAD Panel</Navbar.Brand>
          {user && <Button variant="outline-light" onClick={logout}>Logout</Button>}
        </Container>
      </Navbar>
      <Container className="mt-4 mb-auto">
        {adminAlert ? <NotAdmin /> : user ? <UserList agencyAccessCode={agencyAccessCode} /> : <Login />}
      </Container>
      <footer className="footer py-3 bg-light">
        <Container className="text-center">
          <span className="text-muted">For support, email us at <a href="mailto:support@sepaindustries.com">support@sepaindustries.com</a></span>
        </Container>
      </footer>
    </div>
  );
  }  

export default App;
