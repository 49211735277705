// src/firebase.js

import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";

// Replace the following with your app's Firebase project configuration
const firebaseConfig = {
    apiKey: "AIzaSyD3x7Vm4XXzIRbNpEaD-ckDlFNWY82AUpc",
    authDomain: "nc911mspprd.firebaseapp.com",
    databaseURL: "https://nc911mspprd-default-rtdb.firebaseio.com",
    projectId: "nc911mspprd",
    storageBucket: "nc911mspprd.appspot.com",
    messagingSenderId: "145414418083",
    appId: "1:145414418083:web:1ea86ecda8575f8901d916",
    measurementId: "G-F9TPE4C8L8"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth(app); // Add this line
